:root {
  --font-family: "Ubuntu Sans", "Amazon Ember Regular", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif !important;  
}

.form-control-sm {
  min-height: calc(1.5em + 1.1rem + 3px) !important;
}

.n-bb-grey-1 {
  border-bottom: 1px solid lightgrey !important;
}
.n-tb-grey-1 {
  border-top: 1px solid lightgrey !important;
}
.n-bg-red {
  background-color: red !important;
}
.n-bg-green {
  background-color: green !important;
}

.n-bg-grey {
  background-color: lightgrey !important;
}

.n-form-control {
  height: 2em !important;
  border-radius: 3px !important;
}

.n-color-softblue {
  color: #d1e0ed;
}

.n-color-purple {
  color: #625185;
}

.n-color-navy {
  color: #1C355C;
}

.n-color-blue {
  color: #5e81ac;
}

.n-color-darkgray {
  color: #4C566A;
}

.n-color-darkgreen {
  color: #517C76;
}

.n-color-darkred {
  color: darkred;
}

.n-color-lightgreen {
  color: #7AA9A3;
}

.n-color-green {
  color: #7FA366;
}

.n-color-red {
  color: #BF616A;
}

.n-color-orange {
  color: #F09000;
}

.n-color-royalblue {
  color: #0079A2;
}

.n-color-pink {
  color: #D08770;
}

.nav-link {
  cursor: pointer;
}
.tooltipClass .tooltip-inner {
  background: #dbd4fc !important;
}
.tooltipClass .tooltip-arrow.arrow::before {
  border-top-color: #dbd4fc !important;
}
.n-btn-xs {
  text-align: center !important;
  padding-top: 2px !important;
  padding-bottom: 0px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  font-size: 0.925rem !important;
  display: inline-flex !important;
}
.n-btn-sm {
  text-align: center !important;
  padding-top: 4px !important;
  padding-bottom: 2px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  font-size: 0.925rem !important;
  /* display: inline-flex !important; */
  border-radius: 4px !important;
}
.n-btn-sm-70w {
  text-align: center !important;
  padding-top: 4px !important;
  padding-bottom: 2px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  font-size: 0.925rem !important;
  /* display: inline-flex !important; */
  border-radius: 4px !important;
  width: 70%;
}
.n-btn-sm-100px {
  text-align: center !important;
  padding-top: 4px !important;
  padding-bottom: 2px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  font-size: 0.925rem !important;
  /* display: inline-flex !important; */
  border-radius: 4px !important;
  width: 100px;
}
.btn.n-btn-orange i {
  color: #FFFFFF;
}

.btn.n-btn-orange .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.n-btn-orange .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.n-btn-orange.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.n-btn-orange:hover:not(.n-btn-text):not(:disabled):not(.disabled),
.btn.n-btn-orange:focus:not(.n-btn-text),
.btn.n-btn-orange.focus:not(.n-btn-text) {
  color: #FFFFFF;
  background-color: #fd9437;
  border-color: #fd9437;
}

.btn.n-btn-orange:hover:not(.n-btn-text):not(:disabled):not(.disabled) i,
.btn.n-btn-orange:focus:not(.n-btn-text) i,
.btn.n-btn-orange.focus:not(.n-btn-text) i {
  color: #FFFFFF;
}

.btn.n-btn-orange:hover:not(.n-btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill],
.btn.n-btn-orange:focus:not(.n-btn-text) .svg-icon svg g [fill],
.btn.n-btn-orange.focus:not(.n-btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.n-btn-orange:hover:not(.n-btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill],
.btn.n-btn-orange:focus:not(.n-btn-text) .svg-icon svg:hover g [fill],
.btn.n-btn-orange.focus:not(.n-btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.n-btn-orange:hover:not(.n-btn-text):not(:disabled):not(.disabled).dropdown-toggle:after,
.btn.n-btn-orange:focus:not(.n-btn-text).dropdown-toggle:after,
.btn.n-btn-orange.focus:not(.n-btn-text).dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.n-btn-orange.disabled,
.btn.n-btn-orange:disabled {
  color: #FFFFFF;
  background-color: #fc8b28;
  border-color: #fc8b28;
}

.btn.n-btn-orange.disabled i,
.btn.n-btn-orange:disabled i {
  color: #FFFFFF;
}

.btn.n-btn-orange.disabled .svg-icon svg g [fill],
.btn.n-btn-orange:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.n-btn-orange.disabled .svg-icon svg:hover g [fill],
.btn.n-btn-orange:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.n-btn-orange.disabled.dropdown-toggle:after,
.btn.n-btn-orange:disabled.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.n-btn-orange:not(:disabled):not(.disabled):active:not(.n-btn-text),
.btn.n-btn-orange:not(:disabled):not(.disabled).active,
.show>.btn.n-btn-orange.dropdown-toggle,
.show .btn.n-btn-orange.n-btn-dropdown {
  color: #FFFFFF;
  background-color: #fd9437;
  border-color: #fd9437;
}

.btn.n-btn-orange:not(:disabled):not(.disabled):active:not(.n-btn-text) i,
.btn.n-btn-orange:not(:disabled):not(.disabled).active i,
.show>.btn.n-btn-orange.dropdown-toggle i,
.show .btn.n-btn-orange.n-btn-dropdown i {
  color: #FFFFFF;
}

.btn.n-btn-orange:not(:disabled):not(.disabled):active:not(.n-btn-text) .svg-icon svg g [fill],
.btn.n-btn-orange:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.n-btn-orange.dropdown-toggle .svg-icon svg g [fill],
.show .btn.n-btn-orange.n-btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.n-btn-orange:not(:disabled):not(.disabled):active:not(.n-btn-text) .svg-icon svg:hover g [fill],
.btn.n-btn-orange:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.n-btn-orange.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.n-btn-orange.n-btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.n-btn-orange:not(:disabled):not(.disabled):active:not(.n-btn-text).dropdown-toggle:after,
.btn.n-btn-orange:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.n-btn-orange.dropdown-toggle.dropdown-toggle:after,
.show .btn.n-btn-orange.n-btn-dropdown.dropdown-toggle:after {
  color: #FFFFFF;
}

.n-justify-content-center {
  justify-content: center;
}

.n-justify-content-right {
  justify-content: right;
}

.n-menu-label {
  font-size: 50px;
  font-weight: 700;
  color: #e6e6e6;
}
.n-menu-label-spacing {
    margin: -20px;
    padding: 0px;
}

.n-fa-larger {
  height: 1.5em !important;
}

.n-border-red {
  border: 2px solid orangered !important;
}

.n-bg-white {
  background-color: white !important;
}
.n-bg-dashboard {
  background-color: white !important;
}
.n-bg-candidates {
  background-color: cadetblue !important;
}
.n-bg-technical-specialists {
  background-color: chocolate !important;
}
.n-bg-companies {
  background-color: cornflowerblue !important;
}
.n-bg-contracts {
  background-color: darkolivegreen !important;
}
.n-bg-payroll {
  background-color: darkseagreen !important;
}
.n-bg-billing {
  background-color: firebrick !important;
}
.n-bg-communications {
  background-color: lightsteelblue !important;
}
.n-bg-reports {
  background-color: slategray !important;
}
.n-bg-documents {
  background-color: teal !important;
}
.n-bg-lookups {
  background-color: silver !important;
}
.n-bg-admin {
  background-color: darkorange !important;
}

.n-menu-btn {
    text-align: center !important;
    padding-top: 4px !important;
    padding-bottom: 2px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    font-size: 0.925rem !important;
    /* display: inline-flex !important; */
    border-radius: 2px !important;
    width: 70%;
}
.n-menu-btn-selected {
    background-color: #dbdfea !important;
    /* color: #ffffff !important; */
    border: 1px solid #c2c2ee !important;
}

.n-abtn {
  cursor: pointer;
}

.n-container {
    margin:5px;
}

.n-panel {
  background-color: #ffffff !important;
  border-radius: 4px !important;
  padding: 5px !important;
}

.icon-danger {
  color: #9F4F4C;
}

.icon-green {
  color: var(--bs-success);
}

.icon-gold {
  color: goldenrod;
}

.button-4 {
  appearance: none;
  background-color: #FAFBFC;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 2px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  /* font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; */
  font-family: "Ubuntu Sans", "Amazon Ember Regular",sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.button-4:hover {
  background-color: #F3F4F6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.button-4:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}

.button-4:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.button-4:focus {
  outline: 1px transparent;
}

.button-4:before {
  display: none;
}

.button-4:-webkit-details-marker {
  display: none;
}

.button-13 {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 4px;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  font-family: "Ubuntu Sans", "Amazon Ember Regular",sans-serif;
  font-size: 13px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  /* width: 100px; */
}

.button-13:hover {
  background-color: #f7fafa;
}

.button-13:focus {
  border-color: #008296;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  outline: 0;
}

.button-60 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: .375em;
  box-shadow: none;
  box-sizing: border-box;
  color: #363636;
  cursor: pointer;
  display: inline-flex;
  font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 1rem;
  height: 2.5em;
  justify-content: center;
  line-height: 1.5;
  padding: calc(.5em - 1px) 1em;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-60:active {
  border-color: #4a4a4a;
  outline: 0;
}

.button-60:focus {
  border-color: #485fc7;
  outline: 0;
}

.button-60:hover {
  border-color: #b5b5b5;
}

.button-60:focus:not(:active) {
  box-shadow: rgba(72, 95, 199, .25) 0 0 0 .125em;
}